var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    {
      ref: "notesTodoRef",
      staticClass: "reminders",
      attrs: { color: "#ffffff" }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                {
                  staticClass: "rounded-0 mt-5",
                  attrs: { height: "100%", elevation: "0" }
                },
                [
                  _c("v-data-table", {
                    ref: "table",
                    attrs: {
                      headers: _vm.headers,
                      search: _vm.searchText,
                      items: _vm.notesTodo,
                      "item-key": "reminderKey",
                      height: _vm.tableHeight,
                      loading: _vm.localListLoading,
                      "fixed-header": "",
                      "loading-text": _vm.$t("listLoadingMsg"),
                      "show-select": _vm.editMode,
                      "sort-by": ["reminderDate"],
                      "custom-sort": _vm.customSort,
                      "hide-default-footer": "",
                      "disable-pagination": "",
                      "mobile-breakpoint": _vm.mobileBreakpoint
                    },
                    on: { "click:row": _vm.editReminder },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function(ref) {
                          var items = ref.items
                          var isSelected = ref.isSelected
                          var select = ref.select
                          return [
                            _c(
                              "tbody",
                              {
                                ref: "listContainer",
                                staticClass: "list-container"
                              },
                              _vm._l(items, function(item, index) {
                                return _c(
                                  "tr",
                                  {
                                    key: index,
                                    class: _vm.mobileTableClass,
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { tabindex: "0", role: "row" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.editReminder(item)
                                      },
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.editReminder(item)
                                      }
                                    }
                                  },
                                  [
                                    _vm.editMode
                                      ? _c(
                                          "td",
                                          {
                                            class: _vm.mobileRowClass,
                                            on: {
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                select(item, !isSelected(item))
                                              }
                                            }
                                          },
                                          [
                                            _vm.isMobileMode
                                              ? _c("div", {
                                                  class: _vm.mobileHeaderClass
                                                })
                                              : _vm._e(),
                                            _c("v-checkbox", {
                                              class: _vm.mobileCellClass,
                                              staticStyle: {
                                                margin: "0px",
                                                padding: "0px"
                                              },
                                              attrs: {
                                                "input-value": isSelected(item),
                                                color: !_vm.$vuetify.theme.dark
                                                  ? "#7a7a7a"
                                                  : "#ffffff",
                                                "hide-details": "",
                                                "aria-label": _vm.getReminderTitle(
                                                  item
                                                )
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  select(
                                                    item,
                                                    !isSelected(item)
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.headers[0].text)
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        { class: _vm.mobileCellClass },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.stringifyDate(
                                                item.reminderDate,
                                                true
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.headers[1].text)
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { class: _vm.mobileCellClass },
                                        [
                                          item.noteId
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color:
                                                      _vm.notesTodosStyling
                                                        .notesIconColor
                                                  }
                                                },
                                                [_vm._v("fa-solid fa-note")]
                                              )
                                            : _vm._e(),
                                          item.toDoId
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color:
                                                      _vm.notesTodosStyling
                                                        .toDosIconColor
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "fa-solid fa-clipboard-check"
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                item.reminderType === "TD"
                                                  ? _vm.$t("todoLabel")
                                                  : _vm.$t("notesLabel")
                                              )
                                            )
                                          ]),
                                          _vm.hasText(item.currentDate)
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticClass: "ml-2",
                                                  attrs: { small: "" }
                                                },
                                                [_vm._v("fal fa-repeat")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]),
                                    _c(
                                      "td",
                                      {
                                        class: _vm.mobileRowClass,
                                        style: {
                                          "min-width": _vm.tableWidths.longText
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.rowClicked(
                                              item.reminderText,
                                              item.reminderType,
                                              item
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm.isMobileMode
                                          ? _c(
                                              "div",
                                              { class: _vm.mobileHeaderClass },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.headers[2].text)
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.isQuickRename &&
                                        item.reminderKey ===
                                          _vm.clickQuickRenameKey
                                          ? _c(
                                              "div",
                                              { class: _vm.mobileCellClass },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    "single-line": "",
                                                    autofocus: "",
                                                    error:
                                                      _vm.errorNewQuickReminder,
                                                    "append-icon":
                                                      "fal fa-check",
                                                    maxlength: "225"
                                                  },
                                                  on: {
                                                    blur: _vm.quickRenameBlur,
                                                    "click:append": function(
                                                      $event
                                                    ) {
                                                      return _vm.quickRenameSave(
                                                        item
                                                      )
                                                    },
                                                    keyup: function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.quickRenameSave(
                                                        item
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.newQuickReminderText,
                                                    callback: function($$v) {
                                                      _vm.newQuickReminderText = $$v
                                                    },
                                                    expression:
                                                      "newQuickReminderText"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass: "reminder-text",
                                                class: _vm.mobileCellClass,
                                                staticStyle: {
                                                  "max-width": "280px",
                                                  "white-space": "nowrap",
                                                  "overflow-x": "hidden",
                                                  "text-overflow": "ellipsis"
                                                }
                                              },
                                              [
                                                _c("span", {
                                                  class: _vm.isTrueForTodo(item)
                                                    ? "text-decoration-line-through"
                                                    : "text-decoration-none",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      item.reminderText
                                                    )
                                                  }
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "hover-edit-icon"
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "pl-1",
                                                        attrs: { "x-small": "" }
                                                      },
                                                      [_vm._v("fal fa-pencil")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                      ]
                                    ),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.headers[3].text)
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        { class: _vm.mobileCellClass },
                                        [_vm._v(_vm._s(item.subject))]
                                      )
                                    ]),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.headers[4].text)
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        { class: _vm.mobileCellClass },
                                        [_vm._v(_vm._s(item.student))]
                                      )
                                    ]),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c("div", {
                                            class: _vm.mobileHeaderClass
                                          })
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { class: _vm.mobileCellClass },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "mx-3 hover-button-primary",
                                              attrs: {
                                                small: "",
                                                text: "",
                                                color:
                                                  _vm.contentBreakpoint === "xs"
                                                    ? "primary"
                                                    : "transparent"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.editReminder(item)
                                                }
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("editLabel"))
                                                )
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "mx-3 hover-button-error",
                                              attrs: {
                                                text: "",
                                                small: "",
                                                color:
                                                  _vm.contentBreakpoint === "xs"
                                                    ? "error"
                                                    : "transparent"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.deleteReminder(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("deleteLabel"))
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.selectedRows,
                      callback: function($$v) {
                        _vm.selectedRows = $$v
                      },
                      expression: "selectedRows"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("pb-confirm", { ref: "reminders" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }