import CommonUtils from '@/utils/common-utils';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class NotesTodoMixin extends Vue {
  get searchText() {
    return this.$store.state.notestodo.searchText || '';
  }

  set searchText(value: string) {
    this.$store.commit('notestodo/setSearchText', value);
  }

  get selectedRows() {
    return this.$store.state.notestodo.selectedRows || [];
  }

  set selectedRows(value: Array<any>) {
    this.$store.commit('notestodo/setSelectedRows', value);
  }

  get hasSelectedRows() {
    return CommonUtils.isNotEmpty(this.selectedRows);
  }

  get editMode() {
    return this.$store.state.notestodo.editMode;
  }

  set editMode(value: boolean) {
    this.$store.commit('notestodo/setEditMode', value);
  }

  created() {
    this.editMode = false;
    this.searchText = '';
    this.selectedRows = [];
  }
}
